.Container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - 40px);
  background: #00000090;
  gap: 20px;
  height: inherit;
}

.BoomGif {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 100vw;
}

.TrapWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(121, 9, 9, 1) 30%,
    rgba(121, 9, 9, 1) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  box-shadow: 0 0 20px rgb(137, 0, 0);
  width: 100%;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 26px;

  @media (max-width: 450px) {
    font-size: 20px;
  }
}

.IsPositive {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 124, 9, 1) 30%,
    rgba(0, 124, 9, 1) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  box-shadow: 0 0 20px rgb(34, 137, 0);
}

.TrapName {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.UserWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.Avatar {
  max-width: 50px;
}
