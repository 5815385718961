.Container {
  height: 100%;
}

.PreText {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  text-align: center;
  height: inherit;

  & > :last-child {
    margin-bottom: 60px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}
