@use "../../../helpers/media.scss" as *;

.RoomPage {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 30px;
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__side_container {
    display: flex;
    width: 95%;
    height: 70%;
    justify-content: space-around;

    &__logo {
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.HostView {
  @include mobile {
    flex-direction: column;
    text-align: center;
  }
}
