.slick-slide {
  &:not(.slick-active) img {
    opacity: 0.5;
  }
}

.SliderAvatars {
  img {
    margin: auto;
  }
}
