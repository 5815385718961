.Left_side {
  display: flex;
  position: relative;
  flex: 1;
  height: 100%;

  &.isQuizStared {
    flex: 2.5;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    width: 100%;
    gap: 20px;
    justify-content: center;
    height: inherit;

    &__categories {
      display: flex;
      gap: 40px;
      height: inherit;

      &__wrapper {
        display: flex;
        height: inherit;
        position: relative;
        gap: 24px;
        flex-direction: column;
        align-items: center;

        &__scroll {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          overflow: auto;
          max-height: inherit;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 12px;
          }

          &::-webkit-scrollbar-track {
            background: black;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgb(95, 62, 0);
            border-radius: 20px;
            border: 3px solid black;
          }

          & > button {
            flex-grow: 1;
            box-sizing: border-box;
            min-width: 150px;
            font-size: 20px;
            padding: 14px;

            border-radius: 16px;

            & > img {
              position: absolute;
              opacity: 0.1;
              width: 80%;
              height: 80%;
              pointer-events: none;

              & > svg {
                width: 100%;
                height: 100%;
              }
            }

            & > p {
              z-index: 1;
              color: white;
              font-weight: bold;
            }
          }
        }
      }
    }

    &__logo {
      position: absolute;
      bottom: 0;
    }

    &__image_container {
      overflow: hidden;
      object-fit: cover;
      flex: 10;
      margin: 0 80px;
      box-shadow: 2px 2px 20px #000000;
      border-radius: 20px;

      &__image {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    &__question_number {
      font-weight: bold;
      margin-bottom: 12px;
    }

    &__question {
      font-size: 30px;
      margin: 0 60px;
    }

    &__question_with_image {
      font-size: 30px;
      margin: 0 60px;
      padding-top: 50px;
      flex: 5;
      text-align: center;
    }

    &__logo {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
