$timer_unit2: 1s;
$timer_length2: 15;
$timer_delay2: 0s;

$starting_hue2: 160;
$hue_increment2: 360 / $timer_length2;

@mixin scale_timer2($scale) {
  $x: $scale;
  width: 12vmin * $x;
  height: 12vmin * $x;
  flex: 0 0 12vmin * $x;
  box-shadow:
    0 0 0 (0.3vmin * $x),
    inset (2.5vmin * $x) (2.5vmin * $x) (5vmin * $x) rgba(black, 0.125),
    (2.5vmin * $x) (2.5vmin * $x) (5vmin * $x) rgba(black, 0.125);
  font-size: 6vmin * $x;
  text-shadow: (2.5vmin * $x) (2.5vmin * $x) (5vmin * $x) rgba(black, 0.125);
}

.timerCount2 {
  @include scale_timer2(1.3);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  border-radius: 50%;
  font-weight: 700;
  font-family: "Righteous", cursive;
  border-collapse: separate;

  @media (min-width: 600px) {
    @include scale_timer2(1);
  }

  &:before {
    content: "#{$timer_length2}";
    animation:
      ($timer_unit2 * $timer_length2) $timer_delay2 forwards timer_countdown2,
      $timer_unit2 ($timer_delay2 - $timer_unit2 * 0.125) $timer_length2
        timer_beat2;

    @at-root {
      @keyframes timer_beat2 {
        40%,
        80% {
          transform: none;
        }
        50% {
          transform: scale(1.125);
        }
      }
    }

    @at-root {
      @keyframes timer_countdown2 {
        @for $i from 0 through $timer_length2 {
          #{100% / $timer_length2 * $i} {
            content: "#{-$i + $timer_length2}";
          }
        }
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    background-color: rgba(#e0a000, 0.225);
    animation: ($timer_unit2 * $timer_length2) $timer_delay2 linear forwards
      timer_indicator2;
    border-collapse: separate;

    @at-root {
      @keyframes timer_indicator2 {
        100% {
          top: 100%;
        }
      }
    }
  }
}
