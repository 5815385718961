$timer_unit: 1s;
$timer_length: 30;
$timer_delay: 1s;

$starting_hue: 160;
$hue_increment: 360 / $timer_length;

@keyframes shadowAnim {
  0% {
    box-shadow: inset 0px 0px 0px 0px transparent;
  }
  10% {
    box-shadow: inset 0px 0px 10px -10px rgb(131, 85, 0);
  }
  92% {
    box-shadow: inset 0px 0px 30px rgba(77, 0, 0, 0.998);
  }
  97% {
    box-shadow: inset 0px 0px 3px transparent;
  }
  99% {
    box-shadow: inset 0px 0px 2px white;
  }
  100% {
    box-shadow: none;
  }
}

.shadowBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  animation-fill-mode: forwards;
  z-index: -1;

  &.isShadowTimer {
    animation: shadowAnim 10s ease alternate;
  }
}

@mixin scale_timer($scale) {
  $x: $scale;
  width: 12vmin * $x;
  height: 12vmin * $x;
  box-shadow:
    0 0 0 (0.3vmin * $x),
    inset (2.5vmin * $x) (2.5vmin * $x) (5vmin * $x) rgba(black, 0.125),
    (2.5vmin * $x) (2.5vmin * $x) (5vmin * $x) rgba(black, 0.125);
  font-size: 6vmin * $x;
  text-shadow: (2.5vmin * $x) (2.5vmin * $x) (5vmin * $x) rgba(black, 0.125);
}

.timerCount {
  @include scale_timer(1.5);
  position: absolute;
  z-index: 100;
  top: 80px;
  left: 80px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  border-radius: 50%;
  font-weight: 700;
  font-family: fantasy;
  border-collapse: separate;

  @media (min-width: 600px) {
    @include scale_timer(1);
  }

  &:before {
    content: "#{$timer_length}";
    animation:
      ($timer_unit * $timer_length) $timer_delay forwards timer_countdown,
      $timer_unit ($timer_delay - $timer_unit * 0.125) $timer_length timer_beat;

    @at-root {
      @keyframes timer_beat {
        40%,
        80% {
          transform: none;
        }
        50% {
          transform: scale(1.125);
        }
      }
    }

    @at-root {
      @keyframes timer_countdown {
        @for $i from 0 through $timer_length {
          #{100% / $timer_length * $i} {
            content: "#{-$i + $timer_length}";
          }
        }
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    background-color: rgba(#e0a000, 0.225);
    animation: ($timer_unit * $timer_length) $timer_delay linear forwards
      timer_indicator;
    border-collapse: separate;

    @at-root {
      @keyframes timer_indicator {
        100% {
          top: 100%;
        }
      }
    }
  }
}
