@use "../../helpers/media.scss" as *;

.IntroPage {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  &__buttons_container {
    display: flex;
    text-align: center;
    margin-top: 80px;
    gap: 40px;

    &__create_join {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      gap: 35px;
      color: #adadad;
    }

    @include mobile {
      flex-direction: column;
    }

    & button:first-child {
      @include mobile {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }

  &__form_container {
    display: flex;
    flex-direction: column;
    margin: 80px 24px 0;

    &__error {
      margin-top: 6px;
      margin-left: 15px;
      color: #e0a000;
      font-size: 14px;
      text-transform: uppercase;
    }

    &>div,
    &>button {
      margin-top: 24px;
    }
  }

  &__social_links {
    @media (max-height: 800px) {
      margin-top: 40px;
    }
  }

  &__config-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}