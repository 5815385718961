@keyframes buttonShadowAnim {
  0% {
    box-shadow: 0 0 7px #faad00;
  }
  50% {
    box-shadow: 0 0 7px black;
  }
  100% {
    box-shadow: 0 0 7px #faad00;
  }
}

.OpenButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 7px #faad00;
  border: 1px solid #eec85ef2;
  background-color: #0000007d;
  animation: buttonShadowAnim 3s ease alternate infinite;

  & > svg {
    width: 50px;
    height: 50px;
    transform: rotate(180deg);
  }
}

.DrawerWithTraps {
  display: flex;
  justify-content: center;
  width: fit-content;
  min-width: 100%;
  min-height: 120px;
  padding: 20px 5px;
  background-color: #151515;
  border-top: 1px solid #f78e0452;
  box-sizing: border-box;
  box-shadow: inset 0 0 22px #ffa10012;
}

.TrapContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-right: 1px solid #8c8c8c;
  min-width: 100px;
  min-height: 75px;
  padding: 0px 20px;
  text-align: center;
  justify-content: center;

  &:last-child {
    border: 0;
  }
}

.TrapCount {
  position: absolute;
  top: -5px;
  right: 20px;
  border-radius: 50%;
  font-size: 13px;
  background: #00586066;
  padding: 2px;
  width: 17px;
  height: 17px;
  color: yellow;
  font-weight: bold;
  box-shadow: 0 0 3px yellow;
}

.TrapTitle {
  font-size: 14px;
}

.DrawerWithUsers {
  min-height: 120px;
  background-color: #151515;
  border-top: 1px solid #f78e0452;
  box-sizing: border-box;
  box-shadow: inset 0 0 22px #ffa10012;
}

.UsersContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 20px;
}

.User {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  margin: 16px 8px;
  min-width: 70px;
}

.IsDisabled {
  opacity: 0.2;
}

.Avatar {
  width: 50px;
  height: 50px;
}

.AvatarConfirmUser {
  width: 48px;
  height: 48px;
  margin-top: 2px;
}

.Confirm {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding-top: 3px;
}

.MuiPaper-root {
  border-radius: 10px 10px 0 0;
  border: 1px solid #111111;
}
