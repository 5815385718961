.ScoreBoard {
  &__players_container {
    height: inherit;

    &__best_stand {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 60px;

      &__player {
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
        align-items: center;

        &__name {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }

      &__winner {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        font-size: 40px;
      }
    }

    &__losers_stand {
      display: flex;
      justify-content: center;
      height: inherit;
      max-height: fit-content;

      &__all_lost_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        font-size: 40px;
        height: inherit;

        & > :last-child {
          margin-bottom: 60px;
        }

        img {
          max-width: 100%;
          max-height: 100%;
          overflow: hidden;
        }
      }

      &__lost_container {
        display: flex;
        height: fit-content;
        flex-wrap: wrap;

        &__player {
          margin-top: 80px;
          padding-right: 10px;
          margin-left: 10px;
          border-right: 1px solid white;

          &:last-child {
            border: none;
          }
        }
      }
    }

    &__points {
      color: #00dee6;
      font-weight: bold;
      text-shadow: 1px 1px #018d00;
    }
  }
}
