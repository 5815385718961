.Container {
  height: inherit;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.IsRotate {
  transform: rotate(180deg);
}

.WaitingContainer {
  display: flex;
  gap: 40px;
  flex-direction: column;

  .SliderAvatars {
    margin: 0 -20px;
  }
}

.SliderText {
  margin-top: 60px;
  text-align: center;
}

.Text {
  text-align: center;
}

.ReadyButton {
  & > svg {
    margin-right: 10px;
  }
}

.OptionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-top: 40px;
  margin-top: 40px;

  & > button {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 0%;
      height: 100%;
      transition: width 0.5s;
    }
  }
}

.Option {
  width: 100%;
}

.OptionsInfoWrapper {
  display: flex;
  margin: 30px 40px 10px;
  gap: 55px;
  align-items: center;
}

.OptionsInfo {
  padding-top: 60px;
  text-align: center;
}

.OptionsInfoShowUp {
  text-align: center;
}

.OptionsInfoTraps {
  text-align: center;
  margin-top: 10vh;
  opacity: 0.5;
}

.Arrow {
  width: 120px;
  margin-top: 5vh;
}

.IsArrowHidden {
  display: none;
}

.Logo {
  padding-top: 80px;
  text-align: center;
}

.EndInfo {
  text-align: center;
  padding: 60px 20px;
}

.SocialsContainer {
  margin-top: 40px;
}
