.Right_side {
  flex: 1;
  border-left: 1px solid #7a7a7a;
  height: 100%;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    height: inherit;

    &__top_side {
      display: flex;
      height: calc(100% - 120px);
      padding-bottom: 20px;

      &__connect_wrapper {
        margin-bottom: 20px;
        width: 100%;

        &__room_number {
          display: flex;
          font-size: 22px;
          text-transform: uppercase;
          align-items: center;

          & > svg {
            margin-right: 16px;
          }

          &__id {
            margin-left: 10px;
            font-size: 30px;
            font-weight: bold;
            color: #e0a000;
          }
        }

        & > svg {
          margin-top: 20px;
          border: 6px solid white;
        }
      }

      &__players_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 350px;

        &__title {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          border-bottom: 1px solid #ffffff40;
          padding-bottom: 26px;
          margin-bottom: 30px;

          & > svg {
            margin-right: 16px;
          }
        }

        &__players {
          overflow: hidden;

          &__warning {
            color: rgb(255, 128, 0);
            font-size: 20px;
          }
        }
      }
    }

    .isRoomActive {
      height: 100%;
    }

    & > div > button {
      width: 100%;
      height: 100px;
    }
  }

  .MuiBackdrop-root {
    & > svg {
      width: calc(100vh - 100px);
      height: auto;
      border: 10px solid white;
    }
  }
}
