.player {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 10px;
}

.avatar {
  width: 56px;
  margin-right: 16px;
  opacity: 1;

  &.isOpacity {
    opacity: 0.5;
  }
}

.name {
  & > span {
    color: gold;
  }
}

.trapsContainer {
  display: flex;
  gap: 10px;
  overflow: auto;
  margin: 0 20px;
  overflow: hidden;
}

.trap {
  display: flex;

  & svg {
    max-width: 20px;
  }
}

.points {
  flex: 1;
  text-align: right;
}
